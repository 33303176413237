import React, {useState, useEffect, useRef} from 'react';
import { httpGet } from 'utils/Http';
import AjaxLoader from 'Components/AjaxLoader';
import Button from 'Components/Button';
import * as Scroll from 'react-scroll';
import Wysiwyg from 'Components/Wysiwyg';
import SearchAutocompleteResult from 'Components/SearchAutocompleteResult';
import classNames from 'classnames';
import { dataLayerPush } from 'utils/Datalayer';
import BackgroundImage from 'Components/BackgroundImage';
import s from './HeroSearch.module.scss';

const HeroSearch = ({
    title,
    image = {},
    searchPageUrl,
    inverted,
    backgroundColor,
    linkTitle,
    linkUrl,
    whiteButton,
    wysiwyg,
}) => {
    const autoCompleteRef = useRef();

    const [query, setQuery] = useState('');
    const [fetching, setFetching] = useState(false);
    const [showNoResult, setShowNoResult] = useState(false);
    const [searchResult, setSearchResult] = useState({});
    const [typingTimeout, setTypingTimeout] = useState(0);

    useEffect(() => {
        document.addEventListener(
            'mousedown',
            handleClickOutsideAutoCompleteRef
        );

        return () => {
            document.removeEventListener(
                'mousedown',
                handleClickOutsideAutoCompleteRef
            );
        };
    }, []);

    const handleClickOutsideAutoCompleteRef = (e) => {
        if (
            autoCompleteRef && autoCompleteRef.current &&
            !autoCompleteRef.current.contains(e.target)
        ) {
            setSearchResult({});
            setShowNoResult(false);
        }
    };

    const handleSearchFieldOnKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSubmitOnClick();
        }
    };

    const handleSearchFieldOnChange = (e) => {
        const value = e.currentTarget.value;

        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }

        const newTimeout = setTimeout(() => {
            fetchAutoComplete(value);
        }, 500);

        setQuery(value);
        setSearchResult({});
        setTypingTimeout(newTimeout);
    };

    const fetchAutoComplete = (value) => {
        if (!value || !value.trim() || value.trim().length <= 2) {
            return;
        }

        const searchUrl = `/wt/api/v2/search/?query=${value}&limit=3`;

        setFetching(true);
        setShowNoResult(false);

        httpGet(searchUrl)
            .then((result) => {
                setFetching(false);
                setSearchResult(result);
                setShowNoResult(!result || !result.result.length);
                return result;
            })
            .then((result) => {
                Scroll.scroller.scrollTo('hero-search', {
                    duration: 350,
                    smooth: 'easeIn',
                });

                dataLayerPush({
                    'event': 'search',
                    'searchType': 'hero',
                    'searchInput': value,
                    'hits': result && result.totalHits ? result.totalHits : 0,
                    'citySearch': '',
                    'searchCategory': '',
                });
            })
            .catch(() => {
                setFetching(false);
            });
    };

    const handleSubmitOnClick = () => {
        location.href = `${searchPageUrl}?query=${query}`;
    };

    const buttonClasses = classNames(
        s['HeroSearch__Submit'],
        {[s['HeroSearch__Submit--Active']]: query},
        {[s['HeroSearch__Submit--Alt']]: whiteButton},
    );

    const classes = classNames(
        s['HeroSearch'],
        {[s['HeroSearch--HasImage']]: image && image.src},
        {[s['HeroSearch--Inverted']]: inverted},
    );

    return (
        <BackgroundImage
            image={image}
            className={classes}
            style={{
                backgroundColor,
                contentVisibility: 'visible'
            }}
        >
            <div className={s['HeroSearch__Content']}>
                {title && <h1 className={s['HeroSearch__Title']}>{title}</h1>}
                {wysiwyg && <div className={s['HeroSearch__Preamble']}>{<Wysiwyg wysiwyg={wysiwyg} />}</div>}

                {linkTitle && linkUrl && (
                    <div className={s['HeroSearch__Button']}>
                        <Button
                            modifier={whiteButton ? 'White': ''}
                            href={linkUrl}
                            text={linkTitle}
                        />
                    </div>
                )}

                {searchPageUrl && (
                    <div className={s['HeroSearch__Search']} role="search">
                        <div className={s['HeroSearch__SearchFieldWrapper']}>
                            <input
                                name="hero-search"
                                autoComplete="off"
                                placeholder={'Sök'}
                                className={s['HeroSearch__SearchField']}
                                value={query}
                                onKeyDown={(e) => handleSearchFieldOnKeyDown(e)}
                                onChange={(e) => handleSearchFieldOnChange(e)}
                                type="search"
                            />

                            <SearchAutocompleteResult
                                onHero={true}
                                autoCompleteRef={autoCompleteRef}
                                searchPageUrl={searchPageUrl}
                                showNoResult={showNoResult}
                                context="hero"
                                {...searchResult}
                            />
                        </div>

                        {fetching && (
                            <div className={s['HeroSearch__Loader']}>
                                <AjaxLoader />
                            </div>
                        )}
                        <button
                            onClick={(e) => handleSubmitOnClick(e)}
                            className={buttonClasses}
                            type="submit"
                            aria-label="Sök"
                        />
                    </div>
                )}
            </div>
        </BackgroundImage>
    );
};

export default HeroSearch;
